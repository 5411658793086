import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LockOutlined,
  UserOutlined,
  MailFilled,
  PhoneFilled,
} from "@ant-design/icons";
import { Input, Modal, message } from "antd";
import { axiosLogin } from "../../utils/axiosRequest";

function Login(props) {
  const { onFinish, loading } = props;
  const { t } = useTranslation();
  const [passVisible, setPassVisible] = useState("password");
  const [eyeVisible, setEyeVisible] = useState("hidden");
  const [showSvg, setShowSvg] = useState("hidden");
  const [unshowSvg, setUnshowSvg] = useState("");
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  const forgetPassword = async (e) => {
    e.preventDefault();

    try {
      await axiosLogin.post(`users/reset-pass`, {
        email: e.target[0].value,
      });

      message.success("Рамзи нав ба сурогаи электронии шумо равон карда шуд!");
      setEmail("");
      setOpen(false);
    } catch (error) {
      message.error(error.message);
      setOpen(false);
      setEmail("");
    }
  };

  let eyeBtn = () => {
    if (passVisible.includes("password")) {
      setPassVisible("text");
    } else {
      setPassVisible("password");
    }
  };

  return (
    <div className="xl:block sm:flex sm:justify-center md:flex md:justify-center bg-[#F4F7F9]">
      <div className="absolute w-full h-[100vh]"></div>
      <div
        className="absolute w-full h-[100vh]
          bg-gradient-to-b from-[#19779f] to-transparent"
      ></div>
      <div className="h-screen overflow-hidden md:hidden sm:hidden xl:block">
        <div className="sm:hidden xl:block z-20 absolute top-[20px] w-1/2">
          <p className="absolute text-2xl text-center pl-16 pr-28 text-white z-99">
            {t("GOV_DIGITAL_PAYMENT_SOLUTIONS")}
          </p>
          <img
            className="absolute left-[80px] top-[20px] w-5/6"
            src="/assets/img/gosuslugi.png"
            alt="pay"
          />
        </div>
      </div>
      <div className="w-full xl:w-[40%] max-w-[550px] flex flex-col justify-between pl-4 pb-2 sm:pr-4 md:pr-12 lg:pr-28 xl:pr-28 h-screen md:h-[86vh] overflow-auto z-50 absolute top-0 xl:right-0">
        <div className="bg-white sm:px-4 md:px-8 py-8 md:py-14 mt-6 md:mt-16 w-full flex flex-col space-y-3 rounded-[10px] drop-shadow-xl">
          <h2 className="text-2xl mb-1 sm:text-center md:text-start text-slate-500">
            {t("ENTER")}
          </h2>
          <p className="text-sm mb-1 pb-8 sm:text-center md:text-start text-[#6A6A6A]">
            {/*t("ENTER_NOTE")*/}
          </p>
          <form className="flex flex-col space-y-3" onSubmit={onFinish}>
            <div className="flex flex-row space-x-3 px-2 border-b-2 items-center">
              {/* <img src={emailIcon} alt={t('EMAIL')} /> */}
              <UserOutlined className="text-slate-500 text-[18px] font-bold" />

              <input
                className="w-full py-1 focus:outline-none text-slate-600"
                placeholder={t("EMAIL")}
                required
              />
            </div>
            <div className="flex flex-row space-x-3 px-2 pt-8 border-b-2 items-center">
              {/* <img src={passwordIcon} alt={t('PASSWORD')} /> */}
              <LockOutlined className="text-slate-500 text-[18px] font-bold" />
              {/* <input
                  className="w-full py-1 focus:outline-none"
                  type="password"
                  placeholder={t('PASSWORD')}
                  required
                /> */}

              <input
                className="w-[80%] py-1 focus:outline-none text-slate-600"
                type={passVisible}
                name="password"
                placeholder={t("PASSWORD")}
                required
                onChange={(e) =>
                  e.target.value !== ""
                    ? setEyeVisible("eye block")
                    : setEyeVisible("hidden")
                }
              />
              <span onClick={eyeBtn} className={eyeVisible}>
                <svg
                  className={unshowSvg}
                  onClick={() => {
                    setUnshowSvg("hidden");
                    setShowSvg("");
                  }}
                  viewBox="64 64 896 896"
                  width={15}
                  height={15}
                  fill="#5e5757"
                >
                  <path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path>
                  <path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path>
                </svg>
                <svg
                  className={showSvg}
                  onClick={() => {
                    setUnshowSvg("");
                    setShowSvg("hidden");
                  }}
                  viewBox="64 64 896 896"
                  width={15}
                  height={15}
                  fill="#5e5757"
                >
                  <path d="M81.8 537.8a60.3 60.3 0 010-51.5C176.6 286.5 319.8 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c-192.1 0-335.4-100.5-430.2-300.2z"></path>
                  <path d="M512 258c-161.3 0-279.4 81.8-362.7 254C232.6 684.2 350.7 766 512 766c161.4 0 279.5-81.8 362.7-254C791.4 339.8 673.3 258 512 258zm-4 430c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"></path>
                  <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258s279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766z"></path>
                  <path d="M508 336c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
                </svg>
              </span>
            </div>

            <div className="py-5 pt-8">
              <button
                onLoad={loading}
                className="hover:bg-emerald-600 w-full text-white text-lg rounded-[8px] py-2 bg-[#19779F]"
              >
                {t("ENTER")}
              </button>
            </div>
          </form>
          {/* <div className="flex flex-row space-x-4">
              {t("FORGOT_PASSWORD")}  
              <a className="pl-2 text-sky-700 underline underline-offset-4" onClick={() => {setOpen(true);}}>{t("PASSWORD_RESTORE")}</a>
            </div>
            <div className="flex flex-row space-x-4">
              {t("NO_ACCOUNT")}
              <Link className="pl-2 text-sky-700 underline underline-offset-4" to="/register">{t("NEW_REGISTER")}</Link>
            </div> */}
        </div>

        <div className="flex flex-col md:flex-row justify-start md:justify-between space-y-2 md:space-y-0 items-center">
          <div className="flex flex-row space-x-1.5 items-center">
            <PhoneFilled className="text-slate-500 text-[14px]" />
            <div className="text-[14px] text-slate-500 mb-1">
              {t("SMARTPAY_PHONE")}
            </div>
          </div>
          <div className="flex flex-row space-x-1.5 items-center">
            <MailFilled className="text-slate-500 text-[14px]" />
            <div className="text-[14px] text-slate-500 mb-1">
              {t("SMARTPAY_EMAIL")}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Рамзро фаромуш кардед?"
        open={open}
        onCancel={() => {
          setOpen(false);
          setEmail("");
        }}
        footer
      >
        <form onSubmit={forgetPassword} id="forgetForm">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>
              email:
              <Input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                value={email}
              />
            </label>
          </div>
          <button
            type="submit"
            className="w-[150px] m-2 text-white text-lg rounded-full py-2 bg-[#116072]"
          >
            отправить
          </button>
        </form>
      </Modal>
    </div>
  );
}

export default Login;
