import { lazy } from "react";

// global routes
export const Layout = lazy(() => import("../layout/SmartPayLayout"));
export const Login = lazy(() => import("../model/Login/Login"));

// administrator and supervisor  routes

export const Panel = lazy(() => import("../view/Panel/Dashboard"));
export const Invoices = lazy(() => import("../model/Invoices/Invoices"));
export const InvoiceForms = lazy(() => import("../view/Invoices/Forms"));
export const InvoiceForm = lazy(() => import("../view/Invoices/Form"));
export const Profile = lazy(() => import("../view/Profile/Profile"));
export const CSForms = lazy(()=> import("../view/Invoices/CSForms"));
export const CSFillForm = lazy(()=>import("../view/Invoices/CSFillForm"));
export const SafeCity = lazy(()=>import("../view/Invoices/SafeCity"));

export const Applications = lazy(() =>
  import("../view/Applications/Applications")
);
export const Application = lazy(() =>
  import("../view/Applications/Application")
);
export const Decor = lazy(() => import("../view/Applications/Decor"));

export const Users = lazy(() => import("../model/Users/Users"));
export const Services = lazy(() => import("../model/Services/Services"));
export const Forms = lazy(() => import("../model/Forms/Forms"));
export const FormsCreate = lazy(() => import("../model/Forms/Create"));
export const FormsUpdate = lazy(() => import("../model/Forms/Update"));
export const Recipients = lazy(() => import("../model/Recipients/Recipients"));

// supervisor
export const ProfileOrg = lazy(() => import("../model/ProfileOrg/ProfileOrg"));
