//import 'antd/dist/antd.min.css';
import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import Spinner from "./components/Spinner/Spinner";
import Login from "./model/Login/Login";
import Authpin from './view/Login/Authpin';
//import ReceiptForm from './view/Invoices/ReceiptForm';
import {
  Application,
  Applications,
  Decor,
  Forms,
  FormsCreate,
  FormsUpdate,
  InvoiceForm,
  InvoiceForms,
  Invoices,
  Layout,
  Panel,
  Profile,
  ProfileOrg,
  Recipients,
  Services,
  Users,
  CSForms,
  CSFillForm,
  SafeCity,
} from "./routes/routes";
import { getToken } from "./utils/axiosRequest";
import { RequireAuth } from "./utils/RequreAuth";
import Reports from './view/Reports/Reports';
import SuperuserReports from "./view/Reports/SuperuserReports";
import MobileInvoiceForm from "./view/Invoices/MobileInvoiceForm";
import { isMobile } from "react-device-detect";
import CheckInvoice from "./view/Invoices/CheckInvoice";
import { element } from "prop-types";


function App() {
  // sessionStorage.setItem("isLogged", false);
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();

  let operatorRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          path: '',
          skipLazyLoad: true,
          element: <Navigate to="/panel/invoices" />,
        },

        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            
          ],
        },

        {
          path: "cs/forms",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <CSForms />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "cs/forms/:formId",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <CSFillForm />
              </RequireAuth>
            </Suspense>
          ),
        },
        
        {
          path: "safecity",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <SafeCity />
              </RequireAuth>
            </Suspense>
          )
        },
        {
          path: "applications",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Applications />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Application />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: "decor",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Decor />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },

        {
          path: "reports",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Reports />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let auditorRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="/panel/invoices" />,
        },
        {
          path: "reports",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <SuperuserReports />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            
          ],
        },
        
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let supervisorRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Panel />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "reports",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <SuperuserReports />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            
          ],
        },
        
        {
          path: "cs/forms",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <CSForms />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "cs/forms/:formId",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <CSFillForm />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "applications",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Applications />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Application />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: "decor",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Decor />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "users",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Users />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "services",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Services />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Forms />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_create",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsCreate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_update/:id",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsUpdate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "recipients",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Recipients />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profileOrg",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <ProfileOrg />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let superUserRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Panel />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "checking",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <CheckInvoice />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "cs/forms",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <CSForms />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "cs/forms/:formId",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <CSFillForm />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "safecity",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <SafeCity />
              </RequireAuth>
            </Suspense>
          )
        },
        {
          path: "reports",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <SuperuserReports />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            
          ],
        },
        {
          path: "applications",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Applications />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Application />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: "decor",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Decor />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "users",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Users />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "services",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Services />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Forms />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_create",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsCreate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_update/:id",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsUpdate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "recipients",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Recipients />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profileOrg",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <ProfileOrg />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let billingRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
        },
        
        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            
          ],
        },
        {
          path: "applications",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Applications />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Application />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: "decor",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Decor />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "users",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Users />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "services",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Services />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Forms />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_create",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsCreate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_update/:id",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsUpdate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "recipients",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Recipients />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profileOrg",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <ProfileOrg />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let supervisorMobileRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Panel />
              </RequireAuth>
            </Suspense>
          ),
        },
        {/*
          path: "reports",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <SuperuserReports />
              </RequireAuth>
            </Suspense>
          ),
          */},
        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            
          ],
        },
        
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let supervisorDisableRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
    
    },
  ];

  let performerRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          path: '',
          skipLazyLoad: true,
          element: <Navigate to="/panel/applications" />,
        },
        {
          path: "applications",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Applications />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Application />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: "decor",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Decor />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("isLogged"))) {
      setIsLogged(JSON.parse(sessionStorage.getItem("isLogged")));
    }
  }, [isLogged, navigate]);

  let renderOperatorRoutes = useRoutes(operatorRoutes);
  let renderAuditorRoutes = useRoutes(auditorRoutes);
  let renderBillingRoutes = useRoutes(billingRoutes);
  let renderSupervisorRoutes = useRoutes(supervisorRoutes);
  let renderSuperUserRoutes = useRoutes(superUserRoutes);
  let renderSupervisorMobileRoutes = useRoutes(supervisorMobileRoutes);
  let renderSupervisorDisableRoutes = useRoutes(supervisorDisableRoutes);
  let renderPerformerRoutes = useRoutes(performerRoutes);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/authpin" element={<Authpin />}/>
        <Route path='panel/invoiceview/:invoiceId' element={ <MobileInvoiceForm />} />
      </Routes>
      {getToken()?.user_id === 321 && renderSuperUserRoutes}
      {getToken()?.role_id === 5 && renderPerformerRoutes}
      {getToken()?.role_id === 3 && renderOperatorRoutes}
      {getToken()?.position === 'auditor' && renderAuditorRoutes}
      {getToken()?.role_id === 1 && getToken()?.user_id === 1764 && getToken()?.position !== 'auditor' &&
        getToken().disable === false &&
        renderBillingRoutes}
      {getToken()?.role_id === 1 && getToken()?.user_id !== 1764 && getToken()?.position !== 'auditor' &&
        getToken().disable === false && getToken()?.user_id !== 321 &&
        renderSupervisorRoutes}
      {getToken()?.role_id === 1 && getToken()?.user_id !== 1764 && getToken()?.position !== 'auditor' &&
        getToken().disable &&
        renderSupervisorDisableRoutes}
    </>
  );
}

export default App;
