import React, { useRef, useState } from "react";
import AuthCode from 'react-auth-code-input';
import { useTranslation } from "react-i18next";
import { Form, Button, Modal} from "antd";
import { useNavigate } from "react-router-dom";
import { getBaseURL } from "../../utils/axiosRequest";
import { numberWithSpaces } from "../../utils/GlobalFunctions";


export function CheckInvoice() {
    const { t } = useTranslation();
    const AuthInputRef = useRef(null);
    const [valueInv, setValueInv] = useState('');
    const navigate = useNavigate();
    const InvNumOnChange = (res) => {
        setValueInv(res);
    };
    const [data, setData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(null);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        fetch(`${getBaseURL()}invoices/testing/` + valueInv, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
            }
          });
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleInvNum = () => {
      fetch(`${getBaseURL()}invoices/` + valueInv, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.sessionStorage["access_token"],
        },
      })
  
      .then((response) => {
        if(response.status === 200){
            return response.json(); 
            // showModal();
        }else {
            console.log(new Error(response.status))
            //window.alert('Ҳисобнома ёфт нашуд')
        }
      }).then((rJson) =>{
            setData(rJson);
      }).then(()=>{
            setIsModalOpen(true);
        })
    };

    return (
        <div>
        <Form onFinish={handleInvNum} autoComplete="off">
        <div className="flex w-full text-white sm:text-[18px] md:text-[28px] justify-center pt-4">{t("ENTER_INV_NUM")}</div>
        <Form.Item className="flex w-full justify-center pt-4">
            <AuthCode 
                inputClassName="sm:w-[25px] sm:h-[40px] sm:text-[16px] sm:rounded-[5px] sm:mx-[1.5px] md:w-[35px] md:h-[45px] md:text-[24px] text-center md:mx-1 md:rounded-[8px] border-solid border-[1px] border-[#d6d6d6]"
                onChange={InvNumOnChange}
                ref={AuthInputRef}
                length={12}
                allowedCharacters="numeric" 
            />
        </Form.Item>
        <Form.Item className="flex w-full justify-center pt-4">
              <Button htmlType="submit" className="h-[40px] w-[150px] bg-emerald-600 rounded-[8px] text-white" key="verify">
                  {t("NEXT")}
              </Button>
            </Form.Item>  
        </Form>
        <div>
        
        </div>
        <Modal className="w-full" title="Ҳисобнома" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {isModalOpen ?
        <div className="print:pt-0 print:w-full sm:pt-0 sm:w-full">
          <div className="md:flex w-full print:text-black text-slate-700">
            
            <div className="flex sm:w-full">
                <div className="sm:flex sm:w-full sm:justify-between items-center print:text-black text-slate-800 text-[14px] font-medium">    
                  <div className="md:text-[14px] sm:text-[10px] w-full">
                      <div className="w-full sm:block">
                            <div className="text-[12px] text-slate-600 font-[700]">
                              {`№ ${numberWithSpaces(data.invoice_number)}`}
                            </div>
                            <div className="print:text-black text-slate-600">Сана: {data.create_at}</div>    
                      </div>
                  </div>
                </div>
              </div>
          </div>
        
        <div id = "InvoiceLineBreaker2" className="bg-[#04394f] sm:flex h-[1px]" />
              <div className="text-[10px]">
                    <div className="text-center flex bg-white justify-between text-slate-500">
                      <div className="block text-left">
                        <div >Пардохткунанда</div>
                        <div className="">{data.bill_to.replace('Пардохткунанда:', 'Насаб:').toUpperCase()}</div>
                      </div>
                      <div className="block text-right">
                        <div >Рақами мобилӣ</div>
                        <div className=""> {data.customer_phonenumber}</div>
                      </div>
                    </div>
              </div>
              <div className="sm:block md:flex bg-white justify-between text-slate-600">
                <div className="text-justify text-[10px] font-[500] md:w-2/3">
                  { data.subject['tj']}
                  {/*cleanText(data.subject[i18n.language] !== '' ? data.subject[i18n.language] : data.subject['tj'])*/}
                </div>
                <div className="sm:w-full text-[12px] font-[800] text-center md:w-1/3 md:text-right">
                  {
                     `${numberWithSpaces((data.amount_due/100).toFixed(2))} сомонӣ`
                     
                  }
                </div>
              </div>
              
              
                <div className="mb-4 text-slate-600">
              
                {data.payments.map ((item, index) =>(
                  <div key={index} className="relative z-30 flex border-b-[1px] border-slate-400">
                    <div className="text-[10px] w-[80%] text-left">{`${index+1}. ${item.subject}`}</div>
                    <div className={`text-[10px] w-[20%] text-right`}>{((item.payment_amount/100).toFixed(2))}</div>
                  </div>
                ))}
              </div>
  
          {data.status === 'Charged' ? 
            <div className="stamp_m !absolute text-center mt-[2rem] -translate-y-[130px] md:translate-x-[110px] -rotate-45 z-0"> ПАРДОХТ ШУДААСТ</div> 
            :(data.status === 'void' ? <>
                <div className="flex justify-center items-center bg-white p-[20px]" style={{background:"#fff", padding:"20px"}}>
                  <div className="void_stamp"> БЕКОР КАРДА ШУДААСТ</div>
                </div>
            </> : <></>)}   
              
  
              
              
              
        </div> : <></> 
        }
        </Modal>
        </div> 
    
    );
}

export default CheckInvoice;