import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import moment from "moment";
import { axiosRequest } from "../../utils/axiosRequest";

export const searchInvoice = createAsyncThunk(
  "invoices/getInvoices",
  async ({ id, start, end, skip, limit } = {}, { rejectWithValue, dispatch }) => {
    try {
      let uri = `invoices/search`;
      if (id !== undefined) {
        uri += `?q=${id}`;
      } else {uri += `?q=`;}

      /*if (start !== undefined) {
        uri += `start_date=${start} 00:00:00`;
      } else {
        uri += `start_date=${moment()
          .startOf("year")
          .format("YYYY-MM-DD")} 00:00:00`;
      }

      if (end !== undefined) {
        uri += `&end_date=${end} 00:00:00`;
      } else {
        uri += `&end_date=${moment()
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      }*/

      if (skip !== undefined ) {
        uri += `&skip=${skip}`;
      }
      if (limit !== undefined) {
        uri += `&limit=${limit}`;
      }

      const { data } = await  axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getInvoices = createAsyncThunk(
  "invoices/getInvoices",
  async ({ id, start, end, skip, limit = 20 } = {}, { rejectWithValue, dispatch }) => {
    try {
      let uri = `invoices/list`;

      if (start !== undefined) {
        uri += `?start_date=${start} 00:00:00`;
      } else {
        uri += `?start_date=${moment()
          .startOf("year")
          .format("YYYY-MM-DD")} 00:00:00`;
      }

      if (end !== undefined) {
        uri += `&end_date=${end} 00:00:00`;
      } else {
        uri += `&end_date=${moment()
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      }
      if (id !== undefined) {
        uri += `&q=${id}`;
      }
      if (skip !== undefined ) {
        uri += `&skip=${skip}`;
      }

      if (limit !== undefined) {
        //limit = 20;
        uri += `&limit=${limit}`;
      }

      const { data } = await  axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getReport = createAsyncThunk(
  "invoices/getReport",
  async ({ start, end } = {}, { rejectWithValue, dispatch }) => {
    try {
      let uri = `reports/payments`;

      if (start !== undefined) {
        uri += `?start_date=${start} 00:00:00`;
      } else {
        uri += `?start_date=${moment()
          .startOf("year")
          .format("YYYY-MM-DD")} 00:00:00`;
      }

      if (end !== undefined) {
        uri += `&end_date=${end} 00:00:00`;
      } else {
        uri += `&end_date=${moment()
          .endOf("month")
          .format("YYYY-MM-DD")} 23:59:59`;
      }

      const { data } = await  axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getInvoicesStatus = createAsyncThunk(
  "invoices/getInvoicesStatus",
  async ({ id, start, end } = {}, { rejectWithValue, dispatch }) => {
    try {
      let uri = "invoices/stats";
      if (id !== undefined) {
        uri += `?q=${id}`;
      } else if (start !== undefined) {
        uri += `?start_date=${start}`;
      } else if (end !== undefined) {
        uri += `?end_date=${end}`;
      }
      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getInvoicesTotal = createAsyncThunk(
  "invoices/getInvoicesTotal",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.get("invoices/total");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getMyInvoices = createAsyncThunk(
  "payments/getMyInvoices",
  async ({ id, start, end, skip, limit } = {}, { rejectWithValue, dispatch }) => {
    try {
      let uri = `invoices/my`;
      if (id !== undefined) {
        uri += `?q=${id}`;
      } else {uri += `?q=`;}
      if (start !== undefined) {
        uri += `?start_date=${start}`;
      } else if (end !== undefined) {
        uri += `&end_date=${end}`;
      }
      if (skip !== undefined) {
        uri += `&skip=${skip}`;
      } else {uri += `&skip=0`;}
      if (limit !== undefined) {
        uri += `&limit=${limit}`;
      }
      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getInvoicesMyStatus = createAsyncThunk(
  "invoices/getInvoicesMyStatus",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.get("invoices/my/stats");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postInvoiceCreate = createAsyncThunk(
  "invoices/postInvoiceCreate",
  async ({ body, callback }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.post(`invoices/`, body);
      callback();
      dispatch(setVisible(false));
      dispatch(getInvoices());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const searchApplication = createAsyncThunk(
  "invoices/getInvoicesPaid",
  async ( { id, body, skip, limit } = {}, { rejectWithValue }) => {
    try {
      let uri = `invoices/paid`
       if (skip !== undefined) {
        uri += `?skip=${skip}`;
      } else { uri += `?skip=0`; }
      if (limit !== undefined) {
        uri += `&limit=${limit}`;
      } 
      if (id !== undefined) {
        uri += `&q=${id}`;
      }
 
      const { data } = await axiosRequest.get(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getInvoicesPaid = createAsyncThunk(
  "invoices/getInvoicesPaid",
  async ( { id, body, skip, limit } = {}, { rejectWithValue }) => {
    try {
      //console.log(limit)
      let uri = `invoices/paid`
       if (skip !== undefined) {
        uri += `?skip=${skip}`;
      } else { uri += `?skip=0`; }
      if (limit !== undefined) {
        uri += `&limit=${limit}`;
      } 
      if (id !== undefined) {
        uri += `&q=${id}`;
      }

 
      const { data } = await axiosRequest.get(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postInvoicePaidApply = createAsyncThunk(
  "invoices/postInvoicePaidApply",
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `invoices/paid/apply?invoice_number=${id}`
      );
      callback();
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getInvoicesMyPaid = createAsyncThunk(
  "invoices/getInvoicesMyPaid",
  async ({ skip, limit } = {}, { rejectWithValue }) => {
    try {
      let uri = `invoices/my/paid/`
      if (skip !== undefined) {
        uri += `?skip=${skip}`;
      } else { uri += `?skip=0`; }
      if (limit !== undefined) {
        uri += `&limit=${limit}`;
      }
      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getMyApplications = createAsyncThunk(
  "invoices/getMyApplications",
  async ({ skip, limit } = {}, { rejectWithValue }) => {
    try {
      let uri = `invoices/my/applications`
      if (skip !== undefined) {
        uri += `?skip=${skip}`;
      }
      if (limit !== undefined) {
        uri += `?limit=${limit}`;
      }

      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postInvoiceMyPaidApply = createAsyncThunk(
  "invoices/postInvoiceMyPaidApply",
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `invoices/my/paid/apply?invoice_number=${id}`
      );
      callback();
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postInvoiceFile = createAsyncThunk(
  "invoices/postInvoiceFile",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post("files/upload", body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchInvoiceBankCheck = createAsyncThunk(
  "invoices/patchInvoiceBankCheck",
  async ({ id, body }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.patch(
        `invoices/${id}/bank_check`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      dispatch(getInvoices());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
};

const setLoading = (state) => {
  state.loading = true;
};

const slice = createSlice({
  name: "invoices",
  initialState: {
    invoices: [],
    total: {},
    status: {},
    invoice: {},
    invoiceModal: false,
    loading: false,
    visible: false,
    id: null,
    invoicesPaid: [],
  },
  reducers: {
    changeId: (state, action) => {
      state.id = action.payload;
    },

    setVisible: (state, action) => {
      state.visible = action.payload;
    },

    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    invoiceModalOpenAndClose: (state, action) => {
      state.invoiceModal = action.payload;
    },
    chooseServiceId: (state, action) => {
      state.id = action.payload.id;
      state.invoiceModal = action.payload.invoiceModal;
    },
  },
  extraReducers: {
    [getInvoices.pending]: setLoading,
    [getInvoices.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoices = action.payload;
    },
    [getInvoices.rejected]: setError,
    [getMyInvoices.pending]: setLoading,
    [getMyInvoices.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoices = action.payload;
    },
    [getMyInvoices.rejected]: setError,

    [getInvoicesStatus.pending]: setLoading,
    [getInvoicesStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = action.payload;
    },
    [getInvoicesStatus.rejected]: setError,

    [getInvoicesMyStatus.pending]: setLoading,
    [getInvoicesMyStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = action.payload;
    },
    [getInvoicesMyStatus.rejected]: setError,
    [getInvoicesTotal.pending]: setLoading,
    [getInvoicesTotal.fulfilled]: (state, action) => {
      state.loading = false;
      state.total = action.payload;
    },
    [getInvoicesTotal.rejected]: setError,
    [postInvoiceCreate.pending]: setLoading,
    [postInvoiceCreate.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
      /* window.open(
        `https://smartpay.tj/invoice/${action?.payload?.invoice_uuid}`
      ); */
      notification["success"]({
        message: `#${action?.payload?.invoice_number}`,
        description: "Счет (invoice) успешно создан!",
        
      });
    },
    [postInvoiceCreate.rejected]: setError,
    [getInvoicesPaid.pending]: setLoading,
    [getInvoicesPaid.fulfilled]: (state, action) => {
      state.invoicesPaid = action.payload;
    },
    [getInvoicesPaid.rejected]: setError,
    [postInvoicePaidApply.pending]: setLoading,
    [postInvoicePaidApply.fulfilled]: (state, action) => {
      state.invoice = action.payload;
      notification["success"]({
        message: `#${action?.payload?.invoice_number}`,
        description: "Заявка успешно офермлена!",
      });
    },
    [postInvoicePaidApply.rejected]: setError,
    [getInvoicesMyPaid.pending]: setLoading,
    [getInvoicesMyPaid.fulfilled]: (state, action) => {
      state.invoicesPaid = action.payload;
    },
    [getInvoicesMyPaid.rejected]: setError,
    [getMyApplications.pending]: setLoading,
    [getMyApplications.fulfilled]: (state, action) => {
      state.invoicesPaid = action.payload;
    },
    [getMyApplications.rejected]: setError,
    [patchInvoiceBankCheck.pending]: setLoading,
    [patchInvoiceBankCheck.fulfilled]: (state, action) => {
      state.loading = false;
      message.success("Тахрири документ кабул шуд");
    },
    [patchInvoiceBankCheck.rejected]: setError,
    [postInvoiceMyPaidApply.pending]: setLoading,
    [postInvoiceMyPaidApply.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
      notification["success"]({
        message: `#${action?.payload?.invoice_number}`,
        description: "Заявка успешно офермлена!",
      });
    },
    [postInvoiceMyPaidApply.rejected]: setError,
  },
});

export const {
  changeId,
  invoiceModalOpenAndClose,
  chooseServiceId,
  setInvoice,
  setVisible,
} = slice.actions;

export default slice.reducer;
