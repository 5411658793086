import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosRequest } from "../../utils/axiosRequest";

export const getForms = createAsyncThunk(
  "forms/getForms",
  async (id, { rejectWithValue }) => {
    try {
      var forms = sessionStorage.getItem("forms")
        
      if (forms === null || forms === undefined || forms === 'undefined' || forms === 'null') {
        let uri = `forms/`;
        if (id !== undefined) {
          uri += `?q=${id}`;
        }

        const { data } = await axiosRequest.get(uri);
        sessionStorage.setItem("forms", JSON.stringify(data));
        return data;
      } else { return JSON.parse(forms)}
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);


export const postForm = createAsyncThunk(
  "forms/postForms",
  async (_, { rejectWithValue, dispatch, getState }) => {
    let form = getState().forms.form;
    try {
      let uri = `forms/`;
      let sendForm = { ...form };
      sendForm.name = {
        tj: sendForm.name,
        en: null,
        ru: null,
      };
      sendForm.services = sendForm.form_services;
      delete sendForm.form_services;
      const { data } = await axiosRequest.post(uri, sendForm);
      sessionStorage.removeItem('forms');
      dispatch(getForms());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchForm = createAsyncThunk(
  "forms/patchForm",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const id = getState().forms.id;
    let form = getState().forms.form;
    try {
      let uri = `forms/${id}`;
      let sendForm = { ...form };
      sendForm.name = {
        tj: sendForm.name,
        en: null,
        ru: null,
      };
      sendForm.services = sendForm.form_services;
      delete sendForm.form_services;

      const { data } = await axiosRequest.patch(uri, sendForm);
      sessionStorage.removeItem('forms');
      dispatch(getForms());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getFormById = createAsyncThunk(
  "forms/getFormById",
  async (id, { rejectWithValue }) => {
    try {
      var forms = sessionStorage.getItem("forms")
        
      if (forms === null || forms === undefined || forms === 'undefined' || forms === 'null') {
        let uri = `forms/${+id}`;

        const { data } = await axiosRequest.get(uri);

        return data;
      } else {
        const fr = JSON.parse(forms);
        var returned_form = null;
        fr.forEach((groups, _)=>{
            groups.forms.forEach((form, _) => {
              if (parseInt(form.id) === parseInt(id)) {
                returned_form = form;
              }
            })
          }
        );
        console.log(returned_form);
        return returned_form;
      }
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchDisableForm = createAsyncThunk(
  "forms/patchDisableForm",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.patch(`forms/disable?form_id=${id}`);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  state.authStatus = action.payload.error;
};

const setLoading = (state) => {
  state.loading = true;
};

const initialStateForm = {
  name: "",
  form: [],
  form_services: [],
  is_attend_require: false,
  area_id: 1,
};
const slice = createSlice({
  name: "forms",
  initialState: {
    forms: [],
    form: initialStateForm,
    loading: false,
    id: null,
  },
  reducers: {
    handleChange: (state, action) => {
      const { form = "", name = "", value, index } = action.payload;
      if (form === "") {
        state.form[name] = value;
        return;
      }
      if (name !== "" && form === "form") {
        state.form[form][index][name] = value;
        return;
      }

      state.form[form] = [...value];
    },
    resetState: (state) => {
      state.form = initialStateForm;
    },
    addForm: (state, action) => {
      state.form.form.push(action.payload);
    },
    removeForm: (state, action) => {
      let { index } = action.payload;
      /* console.log(state.form.form[index]);
      console.log(index); */
      state.form.form.splice(index, 1);
    },
  },
  extraReducers: {
    [getForms.pending]: setLoading,
    [getForms.fulfilled]: (state, action) => {
      state.loading = false;
      state.forms = action.payload;
    },
    [getForms.rejected]: setError,
    [getFormById.pending]: setLoading,
    [getFormById.fulfilled]: (state, action) => {
      state.loading = false;
     /*  console.log(action.payload); */
      const { id, name, form, services, area_id } = action.payload;
      state.id = id;
      state.form.name = name?.tj;
      state.form.form = form;
      let arr = [];
      services.forEach((elem) => {
        arr.push(elem.id);
      });
      state.form.form_services = arr;
      state.form.area_id = area_id;
      // state.form = action.payload;
    },
    [getFormById.rejected]: setError,
    [postForm.pending]: setLoading,
    [postForm.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action.payload.id;
      state.invoiceModal = false;
      notification["success"]({
        message: `${action.payload.name?.tj}`,
        description: "Шакл сохта шуд!",
      });
    },
    [postForm.rejected]: setError,
    [patchForm.pending]: setLoading,
    [patchForm.fulfilled]: (state, action) => {
      state.loading = false;
      /* console.log(action.payload, "aaa"); */
      notification["success"]({
        message: `${action.payload.name?.tj}`,
        description: "Шакл илова карда шуд!",
      });
    },
    [patchForm.rejected]: setError,
    [patchDisableForm.pending]: setLoading,
    [patchDisableForm.fulfilled]: (state, action) => {
      state.loading = false;
      notification["success"]({
        message: `Форма`,
        description: "Бекор карда шуд!",
      });
    },
    [patchDisableForm.rejected]: setError,
  },
});

export const { handleChange, addForm, removeForm, resetState } = slice.actions;

export default slice.reducer;
