import React from "react";
import i18n from 'i18next';
import "./receipt.css";
import { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Collapse } from 'antd'; 
import { isMobile } from "react-device-detect";
import { numberWithSpaces, withRouter, API_KEY } from "../../utils/GlobalFunctions";
import { getBaseURL } from "../../utils/axiosRequest";
import Notfound from "../Notfound";
const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );


export class MobileInvoiceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoaded: false,
        data: null,
        payler_session: null,
        key: 'smartpay',
        invoice_total: 0,
        requestFailed: false,
        failedCode: null,
    };
  }

  componentDidMount() {
    
    this.getData();
    
  } 

  componentDidUpdate() {
    
  }

  getData = () => {
    console.log(this.props);
    fetch(`${getBaseURL()}invoices/` + this.props.invoiceId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage["access_token"],
      },
    })

    .then((response) => {
      if(response.status === 200){
          return response.json();     
      }else {
          console.log(new Error(response.status))
          this.setState({ isLoaded:true, requestFailed: true, failedCode: response.status })
          
          //window.alert('Ҳисобнома ёфт нашуд')
      }
    })
        .then((responseJson) => {
          this.setState({ data : responseJson, isLoaded: true });
          
        });
  }

  startPayment = () => {
    //let total_amount = this.state.data.visa_amount;
    //if(!this.state.data.bank_fee_included){
    //  total_amount = (this.state.data.amount_due / (1-this.state.data.bank_fee)).toFixed(0);
    //}
    

    fetch("https://smartpay.tj/subapi/payler/payment_session", 
    {
      method: "POST",
      credentials:"include",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
        'x-app-token': API_KEY(),
      },
      


      body: JSON.stringify({
        'amount': this.state.data.invoice.visa_amount,
        'product': (this.state.data.invoice.subject[i18n.language] === null ? this.state.data.invoice.subject['tj'] : this.state.data.invoice.subject[i18n.language]),
        'lifetime': 43200,
        'invoice_number': this.state.data.invoice.key,
        'currency': 'TJS',
        'test_mode': this.state.data.invoice.test_mode,
      })

    }
    
    )
    
    .then((response) => response.json())
      .then((responseJson) => {
        
        window.location.href = responseJson.payment_url;
        
    });
  
  }

render () {
    const { isLoaded, data, requestFailed } = this.state;
    const { translation } = this.props;
    const { Panel } = Collapse;

return (
<>
  
    <div id = "InvoiceContainer" style={{textAlign: "-webkit-center"}} className="sm:pt-[1rem] block bg-white -z-100 text-center">
      
      {!isLoaded ?
      <div className="flex w-full h-[80vh] justify-center items-center">
        <Spin size="large" indicator={antIcon} />
      </div>
      : requestFailed ?
        <Notfound mstatus={{ code: this.state.failedCode }} /> :
      
      <div className="sm:pt-0 md:pt-[2rem] sm:w-full md:w-[80%]">
        <div className="flex w-full text-slate-700">
          <div className="flex w-[25%] ml-4 justify-center items-center">
            <img alt='' className="md:h-[112px] sm:h-[88px]" src={data.merchant_logo}></img>
          </div>
          <div className="w-[75%] block  text-center">
            <div className="mx-2 sm:text-[14px] md:text-[18px] upper font-semibold">{data.merchant_name[i18n.language]}</div>
            {data.merchant_unit_name[i18n.language] ? 
            <div className="mx-2 text-center upper sm:text-[10px] md:text-[14px] font-semibold">{data.merchant_unit_name[i18n.language]}</div>
            :<></>
            }
            <div className="mx-2 sm:text-[11px] md:text-[13px] text-center py-[5px]" >
              {data.merchant_address} {`${translation('TEL')} ${data.merchant_phonenumber} ${translation('EMAIL')} ${data.merchant_email}`} 
            </div>
          </div>
        </div>
      
      <div id = "InvoiceLineBreaker2" className="bg-[#04394f] sm:flex md:hidden h-[1px]" />
        <div id = "InvoiceHeader" className = "block bg-transparent rounded-t-[10px] sm:px-0 sm:py-[10px] md:py-[0.5rem] md:px-0">
            <div className="flex md:w-full sm:w-full pt-2">
              <div className="sm:flex md:block sm:w-full sm:justify-between items-center text-slate-800 text-[14px] font-medium">    
                <div className="md:text-[20px] sm:text-[16px] w-full">
                    <div className="w-full sm:block lg:flex">
                          <div className="hidden">{window.document.title = `${translation('INVOICE')} ${data.invoice_number}`}</div>
                          <div className="sm:w-full lg:w-2/3 text-slate-600 font-[700] sm:text-center sm:text-[18px] lg:text-left md:text-[18px]">
                            {`${translation('INVOICE')} ${numberWithSpaces(data.invoice_number)}`}
                          </div>
                          <div className="sm:w-full lg:w-1/3 text-slate-600 sm:text-center sm:text-[14px] lg:text-right md:text-[18px]">{translation('DATE')} {data.create_at}</div>    
                    </div>
                </div>
              </div>
            </div>
            </div>
            <div>
                  <div style={{position: 'relative', zIndex: 9}} className="text-left bg-trasnparent sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-slate-900">
                    <div> {translation('PHONE')} {data.customer_phonenumber}</div>
                    <div>{data.bill_to.toUpperCase()}</div>
                  </div>
            </div>
            
            <div>
            {data.status === 'Charged' ? 
              <div className="flex justify-center">
                <div className="text-slate-900 font-semibold text-[14px] z-10">{data.bank}</div>
                <div className="absolute">
                    <div className="stamp_m"> {`ПАРДОХТ ${data.invoice_number.replace('-','')} ШУДААСТ`}</div> 
                </div>
              </div>
              :(data.status === 'void' ? <>
                  <div className="flex justify-center items-center bg-transparent p-[20px]" style={{background:"#fff", padding:"20px"}}>
                    <div className="void_stamp"> {translation('VOID_STAMP')}</div>
                  </div>
              </> : <></>)} 
            </div>
            
            <div className="relative sm:block md:flex bg-transparent justify-between sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-slate-900">
              <div className="text-justify text-[18px] font-[500] md:w-2/3">
                { data.subject[i18n.language]}
                {/*cleanText(data.subject[i18n.language] !== '' ? data.subject[i18n.language] : data.subject['tj'])*/}
              </div>
              <div className="sm:w-full text-[24px] font-[800] text-center md:w-1/3 md:text-right">
                {
                   `${numberWithSpaces((data.amount_due/100).toFixed(2))} ${translation('CURRENCY_1')}`
                   
                }
              </div>
            </div>
            
            <Collapse>
              <Panel header="Маълумоти муфассал" key="1">
              <div className="sm:px-[20px] sm:pb-[10px] mb-4 sm:pt-0 md:pt-[10px] text-slate-600">
            
              {data.payments.map ((item, index) =>(
                <div key={index} className="flex border-b-[1px] border-slate-400">
                  <div className="w-[75%] text-left">{`${index+1}. ${item.subject}`}</div>
                  <div className={`w-[25%] text-right`}>{numberWithSpaces((item.payment_amount/100).toFixed(2))}</div>
                </div>
              ))}
              
            
            {/*<div id = "InvoiceSummary" className="flex border-b-[1px] border-slate-400 py-[2px] font-semibold text-red-600">
              <div className="w-[75%] text-left">{translation('UNPAID')} </div>
              <div className="w-[25%] text-right font-bold text-red-600"> {numberWithSpaces((data.amount_to_pay/100).toFixed(2))} </div>
              
            </div>*/}
            </div>
              </Panel>
            </Collapse>
          
          {data.status === 'open' ? <>
            {isMobile ? <>
              <div className="flex mt-5 justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[14px] py-2 px-8  bg-orange-500 hover:bg-orange-300 hover:text-white hover:font-bold" 
                 onClick={()=>{window.location.href = `http://pay.expresspay.tj/?A=${data.invoice_number}&s=${(data.amount_to_pay /100).toFixed(2)}&c=&f1=318`}}> 
                 <div >{`${translation('PAY_EW')} ${translation('EWALLET')}`}</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-transparent items-center text-center">
                  <img alt='' className='m-auto h-[30px]' src='/assets/img/dc-next.png'></img>
                 </div>
               </button>
              </div>
              <div className="flex justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[14px] py-2 px-8  bg-blue-500 hover:bg-blue-300 hover:text-white hover:font-bold" 
                 onClick={()=>{window.location.href=`eskhata://service/f024382e-67f4-4b3c-bf53-3d552d851555/${data.invoice_number.replace('-','')}/${((data.amount_to_pay - data.amount_paid)/100).toFixed(2)}`
                }}> 
                 <div >{`${translation('PAY_EW')} ${translation('EWALLET')}`}</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-transparent items-center text-center">
                  <img alt='' className='m-auto h-[30px] rounded-full' src='/assets/img/eskhata.webp'></img>
                 </div>
               </button>
              </div>
              </> 
              :<>
                
              </>
            }
            {/* <div className="flex justify-center sm:pb-4 md:py-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[14px] py-2 px-8  bg-indigo-700 hover:bg-indigo-500 hover:text-white hover:font-bold" 
                 onClick={() => this.startPayment()}> 
                 <div >{translation('PAY_EW')}</div>
                 <img alt='' className='pl-4 h-[32px]' src='/assets/img/korty_milly2.png'></img>
                 <img alt='' className='pl-4 h-[16px]' src='/assets/img/visa_white.png'></img>
               </button>
             </div> */}
            </>
          :<></>
          }  
            

            
            
            
          </div>
          }
          <div className="md:h-40"></div>
      </div>
      </>
      );

  } 
  
}


export default withRouter(MobileInvoiceForm) ;