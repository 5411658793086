import React from "react";
import { t } from 'i18next';
import { Component } from "react";
import dayjs from 'dayjs';
import { DatePicker, Drawer, Spin, Popover, Table, Tabs, Pagination, Select, Modal, ConfigProvider} from "antd";
import { LoadingOutlined, FilePdfOutlined, SyncOutlined, FileExcelOutlined } from '@ant-design/icons';
import { numberWithSpaces, withRouter } from "../../utils/GlobalFunctions";
import { getToken, getBaseURL } from "../../utils/axiosRequest";
import { saveAs } from 'file-saver';
const { RangePicker } = DatePicker;


const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

export class SuperuserReports extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
          isLoaded: false,
          periodLoading: false,
          processing: true,
          accounts_data: null,
          void_data: null,
          data: null,
          invoiceInfo: null,
          openInvoiceInfo: false,
          requestFailed: false,
          start: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          end: dayjs().add(1, 'day').format('YYYY-MM-DD'),
          r_start: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          r_end: dayjs().add(1, 'day').format('YYYY-MM-DD'),
          calendarChanged: false,
          sv_name: null,
          sv_status: null,
          pageSize: 20,
          currentPage: 1,
          total_inv: null,
          unitId: 0,
          formId: 0,
          statusInv: null,
          excelDisabled: false,
          merchantId: getToken()?.merchant_id,
          user_id: getToken()?.user_id,
          reportView: false,
          selectedDep: null,
          selectedArea: null,
          selectedMonth: null,
          selectedRegion: null,
          selectedUser: null,
          setError: '',
          reportFilter: null,
          tabKey: 1,
      };
    }
  
    componentDidMount() {
      //this.setState({start: moment().startOf("month")});
      //this.setState({end: moment().endOf("month")})
      this.getData(dayjs().subtract(30, 'day').format('YYYY-MM-DD'), dayjs().add(1, 'day').format('YYYY-MM-DD'));
    }
  
    componentDidUpdate() {
      
    }
    
    getInvoiceInfo = (unit_id, status, page, size) => {
      this.setState({ processing: true, openInvoiceInfo: true });
      //console.log(this.state.total_inv)
      let query = ``
      if (status !== undefined) {
        query += `?status=${status}`;
      }
      //if (form_id !== undefined) {
      //  query += `&form_id=${form_id}`;
      //} else { query += `&form_id=0`;}
      if (unit_id !== undefined) {
        query += `&unit_id=${unit_id.toString()}`;
      } else { query += `&unit_id=0`;}
      if (this.state.start !== null) {
        query += `&start_date=${this.state.start} 00:00:00`;
      } 
      if (this.state.end !== null) {
        query += `&end_date=${this.state.end} 23:59:59`;
      }
      if (page !== null ) {
        query += `&skip=${(page-1) * size}`;
        //query += `&skip=${(this.state.currentPage-1) * this.state.pageSize}`;
      } 
      if (size !== null ) {
        query += `&limit=${size}`;
        //query += `&limit=${this.state.pageSize}`;
      }

      
      fetch(`${getBaseURL()}reports/s_invoice_info${query}`, 
      { 
          method: "GET",
          headers: { 
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + window.sessionStorage["access_token"]
          },
      })
  
      .then((response) => {
        if(response.status === 200){
            return response.json();     
        } else {
            this.setState({ requestFailed: true })
        }
      })
          .then((responseJson) => {
            this.setState({ invoiceInfo : responseJson, processing: false });
            
          });
      

    }

    getAccountsReport =() => {
      this.setState({ excelDisabled: true });
      fetch(`${getBaseURL()}reports/bankaccounts?start_date=${this.state.start} 00:00:00&end_date=${this.state.end} 23:59:59`,
      { 
          method: "GET",
          headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + window.sessionStorage["access_token"]},
          
      }).then(async response => {
          if(response.status === 200){
            let filename = response.headers.get('content-disposition');
            filename = filename.split('"')[1];
            const blob = await response.blob();
            saveAs(blob, filename);
            return filename  
          } else {
            window.alert(`Дархости хато` )
            
          }
      }).then((filename)=>{
          if(filename) {
            window.alert(`Файли фехристи пардохтхо ${filename} бор карда шуд.` ); 
          }
          this.setState({ excelDisabled: false })
          
        })
    }

    getCommonReport = () => {
      this.setState({ excelDisabled: true });
      fetch(`${getBaseURL()}reports/revizor?start_date=${this.state.r_start} 00:00:00&end_date=${this.state.r_end} 23:59:59&unit_id=${this.state.reportFilter}`,
      { 
          method: "GET",
          headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + window.sessionStorage["access_token"]},
          
      }).then(async response => {
          if(response.status === 200){
            let filename = response.headers.get('content-disposition');
            filename = filename.split('"')[1];
            const blob = await response.blob();
            saveAs(blob, filename);
              
          } else {
            window.alert(`Дархости хато` )
            
          }
      }).then((filename)=>{
          window.alert(`Файли омори пардохтхо ${filename} бор карда шуд.` ); 
          this.setState({ excelDisabled: false })
        })
    }

    getData = (start, end) => {
      this.setState({ periodLoading: true, });
      let query = ``
      if (start !== null) {
        query += `?start_date=${start} 00:00:00`;
      } 
      if (end !== null) {
        query += `&end_date=${end} 23:59:59`;
      }

      fetch(`${getBaseURL()}reports/superuser${query}`, 
      { 
          method: "GET",
          headers: { 
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + window.sessionStorage["access_token"]
          },
      })
  
      .then((response) => {
        if(response.status === 200){
            return response.json();     
        } else {
            this.setState({ requestFailed: true })
        }
      })
          .then((responseJson) => {
            this.setState({ data : responseJson, isLoaded: true, periodLoading: false, });
            
          });

      
    }

    getVoidData = (start, end) => {
      this.setState({ periodLoading: true, });
      let query = ``
      if (start !== null) {
        query += `?start_date=${start} 00:00:00`;
      } 
      if (end !== null) {
        query += `&end_date=${end} 23:59:59`;
      }

      fetch(`${getBaseURL()}reports/void_invoices${query}`, 
          { 
              method: "GET",
              headers: { 
                  "Content-Type": "application/json",
                  'Authorization': 'Bearer ' + window.sessionStorage["access_token"]
              },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            } else {
                this.setState({ requestFailed: true })
            }
          })
              .then((responseJson) => {
                this.setState({ void_data : responseJson, isLoaded: true, periodLoading: false, });
                
              });

    }

    getInvoice = (id) => {
      this.setState({ processing: true});
      fetch(`${getBaseURL()}invoices/` + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.sessionStorage["access_token"],
        },
      })
  
      .then((response) => {
        if(response.status === 200){
            return response.json();     
        }else {
            console.log(new Error(response.status))
            this.setState({ isLoaded: true, requestFailed: true, failedCode: response.status })
            
            //window.alert('Ҳисобнома ёфт нашуд')
        }
      })
          .then((responseJson) => {
            //this.setState({ data : responseJson, isLoaded: true });
            this.setState({ processing: false});
            Modal.info({
              width: '60%',  
              title: `${parseInt(responseJson.invoice_number.substring(0,3)) > 899 ? 'Ҳисобрақами шахсӣ': 'Ҳисобнома'} № ${responseJson.invoice_number}`,
              content: 
              <div className="print:pt-0 print:w-full sm:pt-0 sm:w-full">
          <div className="md:flex w-full print:text-black text-slate-700">
            <div className="flex md:w-[70%] sm:w-full">
              <div className="flex print:ml-0 ml-4 justify-center items-center">
                <img alt='' className="w-[56px]" src={responseJson.merchant_logo}></img>
              </div>
              <div className="block  m-4 text-center">
                <div className="mx-2 print:text-[11px] print:mx-0 sm:text-[14px] upper font-semibold">{responseJson.merchant_name['tj']}</div>
                {responseJson.merchant_unit_name['tj'] ? 
                <div className="mx-2 print:text-[11px] print:mx-0 text-center upper sm:text-[14px] font-semibold">{responseJson.merchant_unit_name['tj']}</div>
                :<></>
                }
                <div className="mx-2 print:text-[9px] print:mx-0 sm:text-[11px] md:text-[13px] text-center py-[5px]" >
                  {responseJson.merchant_address} {`тел: ${responseJson.merchant_phonenumber} email: ${responseJson.merchant_email}`} 
                </div>
              </div>
            </div>
            <div className="flex md:w-[30%] sm:w-full">
                <div className="sm:flex sm:w-full sm:justify-between items-center print:text-black text-slate-800 text-[14px] font-medium">    
                  <div className="md:text-[14px] sm:text-[12px] w-full">
                      <div className="w-full sm:block">
                            <div className="print:text-black print:text-[18px] text-[20px] text-slate-600 font-[700]">
                              {`№ ${numberWithSpaces(responseJson.invoice_number)}`}
                            </div>
                            <div className="print:text-black text-slate-600">Сана: {responseJson.create_at}</div>    
                      </div>
                  </div>
                </div>
              </div>
          </div>
        
        <div id = "InvoiceLineBreaker2" className="bg-[#04394f] sm:flex h-[1px]" />
              <div>
                    <div className="print:text-black print:font-semibold print:text-[11px] text-center flex bg-white justify-between sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-slate-500">
                      <div className="print:flex block text-left">
                        <div >Пардохткунанда</div>
                        <div className="print:ml-2">{responseJson.bill_to.replace('Пардохткунанда:', 'Насаб:').toUpperCase()}</div>
                      </div>
                      <div className="print:flex block text-right">
                        <div >Рақами мобилӣ</div>
                        <div className="print:ml-2"> {responseJson.customer_phonenumber}</div>
                      </div>
                    </div>
              </div>
              <div className="print:text-black sm:block md:flex bg-white justify-between sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-slate-600">
                <div className="print:text-[12px] text-justify text-[18px] font-[500] md:w-2/3">
                  { responseJson.subject['tj']}
                  {/*cleanText(data.subject[i18n.language] !== '' ? data.subject[i18n.language] : data.subject['tj'])*/}
                </div>
                <div className="print:text-[16px] sm:w-full text-[24px] font-[800] text-center md:w-1/3 md:text-right">
                  {
                     `${numberWithSpaces((responseJson.amount_due/100).toFixed(2))} сомонӣ`
                     
                  }
                </div>
              </div>
              
              
              <div className="print:text-black sm:px-[20px] md:px-0 sm:pb-[10px] mb-4 sm:pt-0 md:pt-[10px] text-slate-600">
              
                {responseJson.payments.map ((item, index) =>(
                  <div key={index} className="relative z-30 flex border-b-[1px] border-slate-400">
                    <div className="print:text-[12px] w-[85%] text-left">{`${index+1}. ${item.subject}`}</div>
                    <div className={`print:text-[12px] w-[15%] text-right`}>{numberWithSpaces((item.payment_amount/100).toFixed(2))}</div>
                  </div>
                ))}
              </div>
              {responseJson.status === 'open' && parseInt(responseJson.invoice_number.substring(0,3)) > 899 
                ?<>
                  <div className="print:text-black sm:block md:flex bg-white justify-between sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-red-500">
                <div className="print:text-[12px] text-justify text-[14px] font-[500] md:w-2/3">
                  Маблағи пардохти боқимонда
                  {/*cleanText(data.subject[i18n.language] !== '' ? data.subject[i18n.language] : data.subject['tj'])*/}
                </div>
                <div className="print:text-[12px] sm:w-full text-[14px] font-[800] text-center md:w-1/3 md:text-right">
                  {
                     `${numberWithSpaces((responseJson.amount_to_pay/100).toFixed(2))} сомонӣ`
                     
                  }
                </div>
              </div>
                </>
                
                :<></>  
            }

  
          {responseJson.status === 'Charged' ? 
            <div className="stamp_m !absolute text-center mt-[2rem] -translate-y-[130px] md:translate-x-[110px] -rotate-45 z-0"> ПАРДОХТ ШУДААСТ</div> 
            :(responseJson.status === 'void' ? <>
                <div className="flex justify-center items-center bg-white p-[20px]" style={{background:"#fff", padding:"20px"}}>
                  <div className="void_stamp"> БЕКОР КАРДА ШУДААСТ</div>
                </div>
            </> : <></>)}   
              
  
              
              
              
    </div>
          });
            
          });
  
    };
  
    render () {
      const { isLoaded, data, tabKey, void_data, openInvoiceInfo, processing, sv_name, sv_status, pageSize, currentPage, 
          total_inv, unitId, statusInv, periodLoading, reportView, selectedArea, selectedDep, reportFilter, setError, selectedRegion
        } = this.state;
      const { translation } = this.props;

      const columns = [
        {
            title: t("DEPARTMENTS"),
            width: "25%",
            dataIndex: "name",
            key: "name",
            align: 'center',
            render: (subject) => {
              return (
                <Popover overlayStyle={{maxWidth: '400px'}} content={subject} title={t("GOV_SERVICE")} trigger="hover">
                  <p className="cursor-text mline-clamp">
                      {subject}
                  </p>
                </Popover>
              )
            },
        },
        {
            title: "Шумораи хизматрасониҳо",
            align: 'center',
            children: [
                {
                  title: 'пардохт шуд',
                  width: "9%",
                  dataIndex: "charged_qty",
                  key: "charged_qty",
                  align: 'center',
                  render: (charged_qty, row) => {
                    return (
                        <div className="text-center text-[12px] text-emerald-500"
                        >
                          {charged_qty !==0 ? <div className="cursor-pointer" 
                              onClick={(e)=>{
                                
                                  this.setState({total_inv: charged_qty, unitId: row?.id, formId: row?.form_id, statusInv: 'Charged', sv_name: row?.name, sv_status: 'Ҳисобномаҳои пардохт шудааст'});
                                  //console.log(total_inv, charged_qty);
                                  
                                  this.getInvoiceInfo(row?.id, 'Charged', currentPage, pageSize);   
                                }
                              }>
                              {numberWithSpaces(charged_qty)}
                            </div>
                           : <></>}
                        </div>  
                    );
                  },
                },
                {
                  title: 'пардохт нашуд',
                  width: "9%",
                  dataIndex: "open_qty",
                  key: "open_qty",
                  align: 'center',
                  render: (open_qty, row) => {
                  return (
                    <div className="text-center text-[12px] text-[red]">
                    {open_qty !==0 ? <div className="cursor-pointer" 
                          onClick={(e)=>{
                              //console.log(row?.key);
                              this.setState({total_inv: open_qty, unitId: row?.id, formId: row?.form_id, statusInv: 'open',sv_name: row?.name, sv_status: 'Ҳисобномаҳои пардохт нашудааст'});
                              this.getInvoiceInfo(row?.id,  'open', currentPage, pageSize);
                            }
                          }>
                          {open_qty}
                        </div>
                      : <></>}
                    </div>
                  );
                    },
        
                },

                {
                    title: t("SUM"),
                    width: "9%",
                    //dataIndex: "open_qty",
                    key: "total_qty",
                    align: 'center',
                    render: ( row ) => {
                    return (
                      <div className="text-center text-[12px] text-sky-600"
                        onClick={(e)=>{
                          //console.log(row);
                          this.setState({total_inv: row?.open_qty+row?.charged_qty, unitId: row?.id, formId: row?.form_id, statusInv: 'open',sv_name: row?.name, sv_status: 'Ҳисобномаҳо'});
                          this.getInvoiceInfo(row?.id,  '', currentPage, pageSize);
                        }
                      }
                      >
                      {row?.open_qty + row?.charged_qty}
                      </div>
                      );
                      },
          
                  },
        
              ], 
            },

        {
            title: "Маблағи хизматрасониҳо",
            align: 'center',
            children: [
                {
                  title: 'пардохт шуд',
                  width: "15%",
                  dataIndex: "charged_summ",
                  key: "charged_summ",
                  align: 'center',
                  render: (charged_summ, row) => {
                    return (
                        <div className="text-right text-[12px] text-emerald-500">
                          {charged_summ !==0  ? <div className="cursor-pointer" 
                              onClick={(e)=>{
                                  this.setState({total_inv: row?.charged_qty, unitId: row?.id, formId: row?.form_id, statusInv: 'Charged', sv_name: row?.name, sv_status: 'Ҳисобномаҳои пардохт шудааст'});
                                  this.getInvoiceInfo(row?.id,  'Charged', currentPage, pageSize);
                              }}>
                              {numberWithSpaces(charged_summ.toFixed(2))}
                            </div>
                           : <></>}
                        </div>  
                    );
                  },
                },
                {
                  title: 'пардохт нашуд',
                  width: "15%",
                  dataIndex: "open_summ",
                  key: "open_summ",
                  align: 'center',
                  render: (open_summ, row) => {
                  return (
                    <div className={`text-right text-[12px] ${open_summ > 99999 ? 'bg-red-500 text-white pr-[5px]': 'text-[red]'}`}>
                    {open_summ !==0 ? <div className="cursor-pointer" 
                        onClick={(e)=>{
                            this.setState({total_inv: row?.open_qty, unitId: row?.id, formId: row?.form_id, statusInv: 'open', sv_name: row?.name, sv_status: 'Ҳисобномаҳои пардохт нашудааст'});
                            this.getInvoiceInfo(row?.id, 'open', currentPage, pageSize);
                        }}>
                        {numberWithSpaces(open_summ.toFixed(2))}
                      </div>
                      : <></>}
                    </div>
                    );
                    },
        
                },

                {
                    title: t("SUM"),
                    width: "15%",
                    dataIndex: "open_summ",
                    key: "open_summ",
                    align: 'center',
                    render: (open_summ, row) => {
                    return (
                      <div className="text-right text-[12px] text-sky-600">
                      {numberWithSpaces((open_summ + row?.charged_summ).toFixed(2))}
                      </div>
                      );
                      },
          
                  },
        
              ], 
        }
      ];

      const void_columns = [
        {
            title: t("DEPARTMENTS"),
            width: "40%",
            dataIndex: "name",
            key: "name",
            align: 'center',
            render: (subject) => {
              return (
                <Popover overlayStyle={{maxWidth: '400px'}} content={subject} title={t("GOV_SERVICE")} trigger="hover">
                  <p className="cursor-text mline-clamp">
                      {subject}
                  </p>
                </Popover>
              )
            },
        },
        {
          title: "Шумораи хизматрасониҳо",
          align: 'center',
          width: "20%",
          dataIndex: "void_qty",
          key: "void_qty",
          render: (void_qty, row) => {
            return (
              <div className="text-center text-[12px] text-red-500">
                {void_qty !==0 ? 
                <div className="cursor-pointer" 
                    onClick={(e)=>{
                      this.setState({total_inv: void_qty, unitId: row?.id, formId: row?.form_id, statusInv: 'void', sv_name: row?.name, sv_status: 'Ҳисобномаҳои бекор шудааст'});
                      this.getInvoiceInfo(row?.id, 'void', currentPage, pageSize);   
                    }}>
                  {numberWithSpaces(void_qty)}
                </div>: <></>}
              </div>);
            },
        },

        {
            title: "Маблағи хизматрасониҳо",
            align: 'center',
            width: "25%",
            dataIndex: "void_summ",
            key: "void_summ",
            render: (void_summ, row) => {
                    return (
                        <div className="text-right text-[12px] text-red-500">
                          {void_summ !==0 ? <div className="cursor-pointer" 
                              onClick={(e)=>{
                                  this.setState({total_inv: row?.void_qty, unitId: row?.id, formId: row?.form_id, statusInv: 'void', sv_name: row?.name, sv_status: 'Ҳисобномаҳои бекор шудааст'});
                                  this.getInvoiceInfo(row?.id,  'void', currentPage, pageSize);
                              }}>
                              {numberWithSpaces(void_summ.toFixed(2))}
                            </div>
                           : <></>}
                        </div>  
                    );
                  },
                },
                

                
        
      ];

      const invColumns = [
        {
          title: t("INVOICE_NUMBER"),
          dataIndex: "invoice_number",
          key: "invoice_number",
          width: "18%",
          //...getColumnSearchProps('invoice_number', t('INVOICE_NUMBER').toLowerCase()),
          render: (account) => {
              return <div className="text-[12px] hover:font-semibold" onClick={(e) => {this.getInvoice(account)}}>
                {numberWithSpaces(account)}</div>;
          },
          
        },
        {
          title: t("DATE"),
          dataIndex: "create_at",
          key: "create_at",
          width: "12%",
          align: "center",
          render: (date,row) => {
            return <div className="text-[12px]" onClick={(e) => {this.getInvoice(row?.invoice_number)}}>{dayjs(date).format("DD.MM.YYYY HH:mm")}</div>;
          },
        },
        {
          title: 'Маблағи пардохт',
          dataIndex: "amount",
          key: "amount",
          width: "12%",
          align: "center",
          render: (amount,row) => {
            return <div className="text-[12px]" onClick={(e) => {this.getInvoice(row?.invoice_number)}}>{numberWithSpaces((amount/100).toFixed(2))}</div>;
          },
        },
        {
          title: t("PAYER"),
          dataIndex: "fullName",
          key: "fullName",
          //...getColumnSearchProps('fullName', t('PAYER').toLowerCase()),
          render: (name,row) => {
            return <div className="uppercase text-[12px]" onClick={(e) => {this.getInvoice(row?.invoice_number)}}>{name}</div>;
          },
        },
        {
          title: t("APPLICANT_CONTACTS"),
          dataIndex: "customer_phonenumber",
          key: "customer_phonenumber",
          align: "center",
          //...getColumnSearchProps('customer_phonenumber', t('APPLICANT_CONTACTS').toLowerCase()),
          render: (customer_phonenumber,row) => {
            return <div className="text-[12px]" onClick={(e) => {this.getInvoice(row?.invoice_number)}}>{customer_phonenumber}</div>;
          },
        },
        {
          title: t("PERFORMER"),
          dataIndex: "performer",
          key: "performer",
          align: "center",
          //...getColumnSearchProps('customer_phonenumber', t('APPLICANT_CONTACTS').toLowerCase()),
          render: (performer) => {
            return <div className="text-[12px]">{performer}</div>;
          },
        }

      ];

      const tabitems = [
        {
          key: 1,
          label: 'Омори пардохтҳо',
          children: <div className="w-full">
          <div className="w-full flex justify-between px-2 font-semibold text-sky-700"> 
          {data && this.state.merchantId === 2 ? (
          <>  
            <div className="flex items-center uppercase">Тақсимот бо раёсатҳо ва намуди хизматрасониҳо</div>
            
            <button className="flex bg-emerald-600 text-white mb-2 lg:text-md xl:text-lg sm:px-3 sm:py-2 md:px-3 lg:px-5 xl:px-10 md:py-2 lg:py-2 xl:py-2 whitespace-nowrap rounded-[8px] items-center"
              disabled={this.state.excelDisabled}
              onClick={()=>this.setState({reportView: true})}>

            <div className="flex items-center">
              <div className="flex mr-4 items-center">
                {this.state.excelDisabled 
                  ? <SyncOutlined style={{ fontSize: 28, color: "#fff"}} spin />
                  : <FilePdfOutlined color="#fff" />}
                
              </div>
              <div>Зеркашӣ</div>
            </div>
            </button>
          </>
          ):(<></>)
          }
          {data && this.state.merchantId !== 2 && this.state.merchantId !== -1 ? (
          <>  
            <div className="flex items-center uppercase">Тақсимот бо намуди хизматрасониҳо</div>
            
            <button className="flex bg-green-600 text-white mb-2 lg:text-md xl:text-lg 
            sm:px-3 sm:py-2 md:px-3 lg:px-5 xl:px-10 md:py-2 lg:py-2 xl:py-2 
            whitespace-nowrap rounded-[8px] items-center"
            disabled={this.state.excelDisabled}

            onClick={()=>{
            this.setState({ excelDisabled: true });
            fetch(`${getBaseURL()}reports/payments?start_date=${this.state.start} 00:00:00&end_date=${this.state.end} 23:59:59`,
            { 
                method: "GET",
                headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + window.sessionStorage["access_token"]},
                
            }).then(async response => {
              
              if(response.status === 200){
                let filename = response.headers.get('content-disposition');
                filename = filename.split('"')[1];
                const blob = await response.blob();
                saveAs(blob, filename);
                  
              } else {
                window.alert(`Дархости хато` )
                
              }
              
            }).then((filename)=>{
              window.alert(`Файли омор ${filename} бор карда шуд.` ); 
              this.setState({ excelDisabled: false })
            })
          }}
          >

            <div className="flex items-center">
              <div className="flex mr-4 items-center">
                {this.state.excelDisabled 
                  ? <SyncOutlined style={{ fontSize: 28, color: "#fff"}} spin />
                  : <FileExcelOutlined color="#fff" />
                }
              </div>
              <div>Excel</div>
            </div>
          </button>
          </>
          ):(<></>)
          }
          </div>
            
          {periodLoading? <div className="w-full flex justify-center">
              <LoadingOutlined style={{ fontSize: 24, }} spin />
              </div> 
          :<Table columns={columns} dataSource={data} sticky bordered pagination={false}
              
          summary={(pageData) => {
            let totalCharged_summ = 0;
            let totalOpen_summ = 0;
            let totalCharged_qty = 0;
            let totalOpen_qty = 0;
            pageData.forEach(({ charged_qty, open_qty, charged_summ, open_summ }) => {
              totalCharged_qty += charged_qty;
              totalOpen_qty += open_qty;
              totalCharged_summ += charged_summ;
              totalOpen_summ += open_summ;
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                      <div className="font-semibold text-[12px] text-right">{translation('TOTAL_AMOUNT')}</div>
                  </Table.Summary.Cell>
                  
                  <Table.Summary.Cell index={1}>
                    <div className="font-semibold text-[12px] text-right">{numberWithSpaces(totalCharged_qty.toFixed(0))}</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div className="font-semibold text-[12px] text-red-600 text-right">{numberWithSpaces(totalOpen_qty.toFixed(0))}</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <div className="font-semibold text-[12px] text-right">{numberWithSpaces((totalOpen_qty + totalCharged_qty).toFixed(0))}</div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={4}>
                    <div className="font-semibold text-[12px] pr-[5px] bg-emerald-500 text-white text-right">{numberWithSpaces(totalCharged_summ.toFixed(2))}</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <div className="font-semibold text-[12px] pr-[5px] bg-red-500 text-white text-right">{numberWithSpaces(totalOpen_summ.toFixed(2))}</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <div className="font-semibold text-[12px] text-right">{numberWithSpaces((totalOpen_summ + totalCharged_summ).toFixed(2))}</div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                
              </>
            );
          }}
          /> }
        </div>
        },
        {
          key: 2,
          label: 'Бойгони',
          children: <div className="w-full">
            <div className="w-full flex justify-between px-2 font-semibold text-sky-700"> 
              <div className="flex items-center uppercase">Бойгонии ҳисобномаҳои бекоркардашуда</div>
              {data && this.state.merchantId !== -1 ? (
          <>
            
          </>
          ):(<></>)
                }
            </div> 
            
            {periodLoading? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin />
              </div> 
            :<Table columns={void_columns} dataSource={void_data} bordered pagination={false}
              
              summary={(pageData) => {
                let total_summ = 0;
                
                pageData.forEach(({ void_summ }) => {
                  total_summ += void_summ;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                          <div className="font-semibold text-right">{translation('TOTAL_AMOUNT')}</div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <div ></div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <div className="font-semibold text-red-600 text-center">{numberWithSpaces(total_summ.toFixed(2))}</div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    
                  </>
                );
              }} 
            /> }
          </div>
        },

      ];
      
      return (
        <ConfigProvider
        theme = {{"components": {
          "Table": {
            "headerBg": "#19779f",
            "headerColor": "rgba(255, 255, 255, 0.88)"
          }
          }}}
        
      >
        <div className="w-full">
          
          {!isLoaded 
          ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
          : <div className="block m-2">
            
            <div className="flex w-full m-2 sm:flex-col md:flex-col lg:flex-row xl:flex-row sm:items-start md:items-start lg:items-center xl:items-center sm:space-x-0 md:space-x-0 lg:space-x-6 xl:space-x-6 sm:space-y-3 md:space-y-3 lg:space-y-0 xl:space-y-0">
                <div className="ml-4 text-sky-800">Давраи ҳисобот</div>
                <RangePicker
                  defaultValue = {[dayjs().subtract(30, 'day'), dayjs().add(1, 'day')]} 
                  
                  onChange={(val) => {
                    this.setState({start: dayjs(val[0]).format('YYYY-MM-DD')});
                    this.setState({end: dayjs(val[1]).format('YYYY-MM-DD')});
                    this.setState({calendarChanged: true})
                    if (tabKey ===1) {
                      this.getData(dayjs(val[0]).format('YYYY-MM-DD'), dayjs(val[1]).format('YYYY-MM-DD'));
                      
                    } else if (tabKey ===2) {
                      this.getVoidData(dayjs(val[0]).format('YYYY-MM-DD'), dayjs(val[1]).format('YYYY-MM-DD'));
                    }
                    
                  }}
                  
                  
                />
            </div>
            
            <Tabs defaultActiveKey="1" items={tabitems} 
              onTabClick={(key)=>{
                console.log(key, this.state.calendarChanged);
                this.setState({tabKey: key})
                if ((key===2 && !void_data) || (key===2 && this.state.calendarChanged)) {
                  this.getVoidData(this.state.start, this.state.end)
                  this.setState({calendarChanged: false})
                } else if ((key===1 && !data) || (key===1 && this.state.calendarChanged)) {
                  this.getData(this.state.start, this.state.end)
                  this.setState({calendarChanged: false})
                }
              }}
                  
            ></Tabs>

            <Drawer className="w-full"
              placement='top'
              height='100%'
              open={openInvoiceInfo}
              onClose={() => {this.setState({currentPage:1, openInvoiceInfo: false});}}
              style={{ overflowX: "hidden" }}
              bodyStyle={{ paddingBottom: 80 }}
              getContainer={false}
              destroyOnClose={true}
            >
        
      
            {processing ? (<Spin indicator={antIcon} />):(
              <div className="h-[120vh] w-full">
                <div className="font-semibold">{sv_name}</div>
                <div className="mb-4 font-semibold">{sv_status}</div>
                <Pagination
                  className="mt-2 ml-4"
                  onChange={(page, size)=>{
                    this.setState({currentPage: page, pageSize: size});
                    this.getInvoiceInfo(unitId, statusInv, page, size);
                  }}
                  onShowSizeChange = {(page, size)=>{
                    this.setState({currentPage: page, pageSize: size});
                    this.getInvoiceInfo(unitId, statusInv, page, size);
                  }}
                  total={Math.ceil(total_inv)}
                  current={currentPage}
                  pageSize={pageSize}
                  hideOnSinglePage = {true}
                  
                />
                 <Table columns={invColumns} dataSource={this.state.invoiceInfo} bordered pagination={false}></Table>   
              </div>
              )}
            </Drawer>
            { this.state.merchantId === 2 ?     
              <Drawer className="w-full"
                placement='right'
                width='50%'
                open={reportView }
                onClose={() => {this.setState({reportView: false});}}
                style={{ overflowX: "hidden" }}
                getContainer={false}
                destroyOnClose={true}
              
              >
              <div>
                <div className="text-14 font-semibold w-full text-center mb-4">Барои гирифтани ҳисобот дар формати Excel, параметрои зеринро интихоб намоед</div>
                <div className="flex m-2">
                  <div className="w-1/3 flex items-center">Интихоби давраи ҳисобот</div>
                  <RangePicker 
                    onCalendarChange={(val) => {
                      this.setState({r_start: dayjs(val[0]).format('YYYY-MM-DD')});
                      this.setState({r_end: dayjs(val[1]).format('YYYY-MM-DD')});
                      this.setState({calendarChanged: true,})
                    }}
                    /*onOpenChange={(status)=>{
                      if (!status) {
                        this.getData();
                        
                      }
                    
                    }}*/
                  />
                </div>
                <div className="flex m-2">
                  <div className="w-1/3 flex items-center">Интихоби раёсат</div>
                  <Select className="w-2/3" options={data.map((elem, index) => ({
                      label: elem.name,
                      value: index,
                    }))}
                    onChange={(value)=>{
                      this.setState({selectedDep: value});
                      //console.log(selectedDep);
                    }}
                  ></Select>
                </div>
                <div className="flex m-2">
                
                  <div className="w-1/3 flex items-center">Интихоби вилоят</div>
                  <Select className="w-2/3" 
                    options={data[selectedDep === null ? 0 : selectedDep].children.map((elem, index) => ({
                      label: elem.name,
                      value: index,
                    }))}
                    onChange={(value)=>{
                      this.setState({selectedArea: value});
                      //console.log(selectedArea);
                      
                    }}
                  />
                </div>

                <div className="flex m-2">
                
                  <div className="w-1/3 flex items-center">Интихоби ноҳия</div>
                  {<Select className="w-2/3" 
                    options={data[selectedDep === null  ? 0 : selectedDep].children[selectedArea === null  ? 0 : selectedArea].children.map((elem, index) => ({
                      label: elem.name,
                      value: index,
                    }))}
                    onChange={(value)=>{
                      this.setState({selectedRegion: value});
                      //data[selectedDep].children[selectedArea].children.map((elem, _) =>console.log(elem.name))
                    }}
                    
                  />}
                </div>
                
                <div className="flex m-2">
                {!data[selectedDep === null ? 0 : selectedDep].children[selectedArea === null ? 0 : selectedArea].children[selectedRegion === null ? 0 : selectedRegion].children
                    ? <></> :<>
                  <div className="w-1/3 flex items-center">Интихоби корманд</div>
                  
                  <Select className="w-2/3" 
                    options={data[selectedDep === null ? 0 : selectedDep].children[selectedArea === null ? 0 : selectedArea].children[selectedRegion === null ? 0 : selectedRegion].children.map((elem, _) => ({
                      label: elem.name,
                      value: elem.id,
                    }))}
                    onChange={(value)=>{
                      this.setState({reportFilter: value});
                      //data[selectedDep].children[selectedArea].children.map((elem, _) =>console.log(elem.name))
                    }}
                    
                  /></>}
                </div>
                
              </div>
              <div className="flex w-full justify-center m-4 text-red-500">{setError}</div>
              <div className="flex w-full justify-center m-4">
                <button className="flex bg-emerald-600 text-white mb-2 lg:text-md xl:text-lg sm:px-3 sm:py-2 md:px-3 lg:px-5 xl:px-10 md:py-2 lg:py-2 xl:py-2 whitespace-nowrap rounded-[8px] items-center"
                  disabled={this.state.excelDisabled}
                  onClick={()=>{
                    //console.log(reportFilter);
                    if (reportFilter !== null){
                      //console.log(reportFilter);
                      //this.setState({setError: 'Ин функсионал рузхои наздик дастрас мешавад'});
                      this.getCommonReport();
                    } else {
                      this.setState({setError: 'Барои зеркашии файли Excel, параметрҳоро бояд дуруст интихоб намоед'});
                    }
                  }}>

                <div className="flex items-center">
                  <div className="flex mr-4 items-center">
                    {this.state.excelDisabled 
                      ? <SyncOutlined style={{ fontSize: 28, color: "#fff"}} spin />
                      : <FilePdfOutlined color="#fff" />}
                    
                  </div>
                  <div>Excel</div>
                </div>
              </button>
              </div>

              </Drawer> 
            : <></>}
          </div>
          
          }
             
            
        </div>

        </ConfigProvider>
        );
  
    } 
    
  }
  
  
  export default withRouter(SuperuserReports) ;