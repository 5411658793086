import React from "react";
import { useParams, Navigate, useNavigate, useLocation  } from "react-router-dom";
import { Modal, Form} from 'antd';
import { deviceDetect } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function shortQuantity(param) {
  let firstLetter = param.slice(0, 1);
  let lastLetters = param.slice(-2);
  return isMobile ? `${firstLetter}-${lastLetters}` : param;
}

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function API_KEY() {
  return 'bfb9f8d8071553ff521ed26bcd0452e5d06e36c92284cc253608550f8af7d757'
}

export function destroyToken() {
  sessionStorage.clear()
  //sessionStorage.removeItem("access_token");
  //sessionStorage.removeItem("isLogged");
  //sessionStorage.removeItem("Forms");
  //   sessionStorage.removeItem("refresh_token");
}

export function transliterate(word){
  word = word.replace("Ғ",'"Г"')
          .replace("Ӣ","И")
          .replace("Қ","Қ")
          .replace("Ӯ","У")
          .replace("Ҳ","Х")
          .replace("Ҷ","Ч");

 return word;
}

export function errorHandler (response, function_name, navigate) {

  if (response.status === 200) {
    return response.json()}
  else if(response.status === 401 || response.status === 403) {
    const isLogined = sessionStorage.getItem('isLogged');
    destroyToken();
    if (isLogined) {Modal.warning({
      title:'Сессия тамом шуд!',
      content: (<div>Вақти сессияи браузер тамом шуд. Илтимос ба система аз дигар ворид шавед!</div>),
    });
  } else {
    Modal.error({
      title:'Воридшавии нодуруст!',
      content: (<div>Логин ёки рамз хато ворид карда шуд!</div>),
    });
  }
    
    navigate('/');
  }
  else if(response.status === 409 || response.status === 422) {
    response.json()
      .then(respjson =>{
    
    const err =  respjson
    const device = JSON.parse(JSON.stringify(deviceDetect()));
    
    Modal.error({
      onOk() {
        /*console.log(JSON.stringify({
          'api_url': response.url,
          'function_name': function_name, 
          'server_error': {'device': device, 'error': err.detail},
          'status': response.status,
        }))*/
      fetch(`https://smartpay.tj/api/billing/error`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.sessionStorage["access_token"],
        },
        body: JSON.stringify({
          'api_url': response.url,
          'function_name': function_name, 
          'server_error': err.detail,
          'device': device,
          'status': response.status,
        })
      })
    },
    
      title:'Амалиёти нодуруст!',
      content: (
        <div className='flex w-full mb-2'>
          Ба дархости шумо аз тарафи сервер чавоби нодуруст омад. 
          Барои баланд бардоштани сифати барнома, маълумотҳои техникӣ ба Smartpay равон карда мешавад.
        </div>),
      
    });
    });
    navigate('/panel');
    return null
  }  
  else {
    Modal.warning({
      title:'Дархости нодуруст!',
      content: (<div>Дархост дар тарафи сервер нодуруст иҷро шуд!</div>),
    });
    
  }
}

export const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [form]= Form.useForm();
  const { t } = useTranslation("", "", "", { useSuspense: false });

  return <WrappedComponent {...props} 
    params={params} 
    translation={t} 
    navigate={navigate} 
    location={location}
    form = {form}
  />;
};

export function validate_rs(rs, bic) {
  let result = true;

  if (!validate_bic(bic)) {
      result = false;
//console.log('bic check false');
  } else if (/[^0-9]/.test(rs)) {
      result = false;
  } else if (rs.length !== 20) {
      result = false;
  } else {
      if (add_checksum_rs(rs, bic) === rs) {
          result = true;
      } else {
          result = false;
      }
  }

  return result;
}

function add_checksum_rs(rs, bic) {
  let v_Rs = '';
  const rsPattern = /^\d{19,20}$|^\d{8}.\d{11}$/;
  const bicPattern = /^\d{9}$/;

  if (rsPattern.test(rs) && bicPattern.test(bic)) {
      const v_Rs1 = rs.slice(0, 8);
      let checksum_value = 0;
      const v_Rs2 = rs.slice(-11);
      const v_bic3 = bic.slice(-3);
      const v_bic_Rs = v_bic3 + v_Rs1 + '0' + v_Rs2;

      const multipliers = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

      for (let i = 0; i < multipliers.length; i++) {
          checksum_value += multipliers[i] * parseInt(v_bic_Rs.charAt(i), 10);
      }

      checksum_value = (checksum_value % 10 * 3) % 10;
      v_Rs = v_Rs1 + checksum_value.toString() + v_Rs2;
  }

  return v_Rs;
}

function validate_bic(bic) {
  let result = true;

  if (bic === '') {
      result = false;
console.log('bic is null');
  } else if (/[^0-9]/.test(bic)) {
      result = false;
console.log('bic must digits');
  } else if (bic.length !== 9) {
      result = false;
console.log('bic must be 9digit');
  }

  return result;
}
