
import { Modal, Button, Form} from "antd";
import AuthCode from 'react-auth-code-input';
import { CheckCircleOutlined, StopOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { saveToken, getBaseURL} from "../../utils/axiosRequest";

export const Authpin = (props) => {
    let { state } = useLocation();
    const { t } = useTranslation();
    const [is2FAModalOpen, setIs2FAModalOpen] = useState(true);
    const [value2FA, setValue2FA] = useState('');
    const AuthInputRef = useRef(null);
    const navigate = useNavigate();

    const handle2FACancel = () => {
        setIs2FAModalOpen(false);
        navigate("/");
      };
    
    const AuthOnChange = (res) => {
        setValue2FA(res);
      };

      const handle2FALogin = () => {
    
        fetch(`${getBaseURL()}otp/login_validate`, { 
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + state.access_token
            },
            body: JSON.stringify({"code": value2FA}),
        }).then((response) => { 
          if(response.status === 200){
            sessionStorage.setItem("isLogged", JSON.stringify(true));
            saveToken(state.access_token);
            navigate("/panel");
          } else {
            window.alert(t("2FA_ERROR"));
            navigate("/")
          }   
          });
          //AuthInputRef.current?.clear()
          //setIs2FAModalOpen(false);
      };

    return(
        <Modal
          title={ t("2FA_3STEP") } 
          width={ 380 }
          open={is2FAModalOpen} onCancel={handle2FACancel}
          footer={[]}
        >
          <Form onFinish={handle2FALogin}
            autoComplete="off">
              <div className="pb-2">{t("2FA_31STEP")}</div>
              <Form.Item className="flex justify-center">
                <AuthCode 
                  inputClassName="w-[45px] h-[45px] text-[24px] text-center mx-1 rounded-[8px] border-solid border-[1px] border-[#d6d6d6]"
                  onChange={AuthOnChange}
                  ref={AuthInputRef} />
              </Form.Item>
          
            <div className="py-2 border-b-[1px] border-grey-400"></div>
            <div className="flex justify-center py-2">
            <Form.Item>
              <Button htmlType="submit" className="bg-violet-600 rounded-[8px] text-white" key="verify">
                  {t("2FA_VERIFY")}
              </Button>
            </Form.Item>  
            </div>
          
          </Form>
        </Modal>
    );
};

export default Authpin;